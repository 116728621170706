import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import getFromData from "../helperFunctions/getFromData";

function RequirementsAndResponsibilities(props) {

    const data = useContext(DataContext);

    const formatTextToBulletList = (text) => {
        const lines = text.split("\n");
        return (
            <ul className="list-disc">
                {lines.map((line, index) => line.trim() ? <li key={index}>{line}</li> : null)}
            </ul>
        );
    };

    return (
        <section className="navy-bg flex flex-col lg:flex-row w-full p-8 md:p-20 text-white gap-9">
            <div className="text-white flex flex-col flex-1">
                <p className="font-bold pb-4 ">REQUIREMENTS</p>
                {getFromData !== "-" && formatTextToBulletList(getFromData("Requirements", data))}
            </div>
            <div className="text-white flex flex-col flex-1">
                <p className="font-bold pb-4">RESPONSIBILITIES</p>
                {getFromData !== "-" && formatTextToBulletList(getFromData("Responsibilities", data))}
            </div>
        </section>
    );
}

export default RequirementsAndResponsibilities;
