import React, { useContext } from "react";
import LogoPicture from "../assets/logo_white_transparent.png";
import ArrowPicture from "../assets/arrow.png";
import TrustIcon from "../assets/trust_icon.png";
import RaisingIcon from "../assets/raising_icon.png";
import UniqueIcon from "../assets/unique_icon.png";
import SerendipityIcon from "../assets/serendipity_icon.png";
import ThoughtfulIcon from "../assets/thoughtful_icon.png";
import EditableTextWithLinks from "../components/EditableTextWithLinks";
import { SettingsContext } from "../context/SettingsContext";
import { DataContext } from "../context/DataContext";
import getFromData from "../helperFunctions/getFromData";
import EditablePinkButton from "../components/EditablePinkButton";
import { Typewriter } from 'react-simple-typewriter'

const icons = [
  { icon: TrustIcon, text: "TRUST" },
  { icon: RaisingIcon, text: "RAISING THE BAR" },
  { icon: UniqueIcon, text: "UNIQUE" },
  { icon: SerendipityIcon, text: "SERENDIPITY" },
  { icon: ThoughtfulIcon, text: "THOUGHTFUL" },
];

function extractFirstName(str) {
  const firstName = str.split(" ")[0];
  return firstName;
}


function Header(props) {

const data = useContext(DataContext);
const settings = useContext(SettingsContext);

  return (
    <header className="light-turquoise-bg flex w-full pt-10 md:pt-20 flex-col">
      <div className="flex flex-col md:flex-row">
        <a
          href="https://keysearch.com"
          target="_blank"
          rel="noopener noreferrer"
          className="block md:hidden pb-8"
        >
          <img
            src={LogoPicture}
            alt="logo"
            width={140}
            className="hover:scale-110"
          />
        </a>

        <div
          id="dark-turq-section"
          className="dark-turquoise-bg p-8 md:p-24 w-full md:w-3/4 mr-10 flex relative flex-row"
        >
          <div className="absolute -right-6 bottom-8 md:flex flex-col items-center hidden">
            <a
              href={getFromData("ConsultantWebsiteLink", data)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="rounded-full hover:scale-105"
                src={getFromData(settings.preview ? "OwnerPhoto":"ConsultantPhoto", data)}
                width={120}
                alt="Consultant"
                style={{
                  boxShadow: "3px 3px 7px 0px rgba(0,0,0,0.75)",
                }}
              />{" "}
            </a>
            <img
              className="mt-6"
              src={ArrowPicture}
              width={70}
              alt="white arrow"
            />
          </div>
          <div className="leftBracket hidden md:block" />
          <div className="flex flex-col mr-0 md:mr-10">
            <p className="text-white text-3xl font-extrabold mb-5">
              Hello
              <br />
              {!settings.preview ? extractFirstName(getFromData("CandidateName", data)) : "Candidate Name"},
            </p>
            <p className="bigger-body-text">
              This document is your personalized guide to what could be the next
              step in your career as
            </p>
            <EditableTextWithLinks className="py-5 text-2xl font-bold light-turquoise-text"
            enableEditing={settings.editable}
            dataKey="OpportunityTitle"
            />
             
            
            <p className="bigger-body-text mb-8">
              Given your background at {!settings.preview ? getFromData("CandidateCurrentCompany", data) : "<Current Company>"} I believe
              you could be a great fit.
            </p>
            <p className="bigger-body-text">
              I hope you find the information in this guide valuable.
            </p>
          </div>
        </div>
        <div className="flex flex-col pt-2 pb-6">
          <a
            href="https://keysearch.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hidden md:block"
          >
            <img
              src={LogoPicture}
              alt="logo"
              width={140}
              className="hover:scale-110"
            />
          </a>

          <div className="flex justify-center gap-4 mt-5 md:mt-auto ">
            <a
              href={getFromData("ConsultantWebsiteLink", data)}
              target="_blank"
              rel="noopener noreferrer"
       
            >
              <img
                className="rounded-full hover:scale-105 md:hidden "
                src={settings.preview ? getFromData("OwnerPhoto", data) : getFromData("ConsultantPhoto", data)}
                width={120}
                alt="Consultant"
                style={{
                  boxShadow: "3px 3px 7px 0px rgba(0,0,0,0.75)",
                }}
              />{" "}
            </a>

            <div className="mt-auto text-white flex flex-col mr-6 font-semibold text-sm">
              <p>{getFromData(settings.preview ? "OwnerName" : "ConsultantName", data)}</p>
              <p className="mb-4">{"Partner"} at Key Search</p>
              <img
                src={settings.preview ? getFromData("OwnerSignature", data) :  getFromData("ConsultantSignature", data)}
                alt="signature"
                width={140}
                style={{ filter: "brightness(0) invert(1)" }}
              />
            </div>
          </div>
        </div>
      </div>
      <h2 className="header-2 mt-10">ABOUT US</h2>
      <p className="subheader-2 mb-10">

      <Typewriter
          words = {['The best Executive Search experience']}
          cursor = { true }
          loop = {1}
        />


      </p>
      <p className="px-8 md:px-20 text-center text-white font-light mb-10">
        At Key Search we are dedicated to reshaping the landscape of executive
        search to ensure exceptional leadership hires for our clients providing
        a unique approach to executive search. We actively seek diverse
        candidates, employ advanced methods like psychometric testing for
        objective assessments, and measure success by the long-term commitment
        and impact of the executives we place. Our expertise extends to
        understanding our client’s industry, business strategy, and unique needs
        which you will find in the document below. We invite you to study the
        document below presenting the unique opportunity to join our clients to
        ultimately help them achieve their vision thanks to your unique blend of
        professional expertise, human qualities, and cultural alignment.'
      </p>
      <p className="px-8 md:px-32 text-center text-white">
        Our values are at the heart of everything we do. We believe in sharing
        our values with our clients, candidates and partners
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 justify-center mt-10 mx-12 md:mx-16 lg:mx-32">
        {icons.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center h-full hover:scale-105"
          >
            <img
              src={item.icon}
              alt="icon"
              className={`h-20 w-20 object-contain`}
            />
            <p className="text-white font-bold text-center">{item.text}</p>
          </div>
        ))}
      </div>

      <EditablePinkButton text="Learn more about Key Search" hardcodedUrl="https://keysearch.com" className="my-20"/>

    
    </header>
  );
}

export default Header;
