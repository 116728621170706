/* eslint-disable react-hooks/rules-of-hooks */

const getFromData = (key, data) => {

    if (data[key]) {
      return data[key];
    } else {
      return "-";
    }

  }

  export default getFromData;
