import React, { useContext } from "react";
import { DataContext } from "../context/DataContext";
import "./MainPage.css";
import Header from "../sections/Header";
import OurClient from "../sections/OurClient";
import JobDescription from "../sections/JobDescription";
import RequirementsAndResponsibilities from "../sections/ReqsAndResps";
import ThankYou from "../sections/ThankYou";
import HowToProceed from "../sections/HowToProceed";
import getFromData from "../helperFunctions/getFromData";
import NdaSection from "../sections/SignNda";
import { SettingsContext } from "../context/SettingsContext";

function MainPage() {
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);

  return (
    // make background styled with the .graphite-bg class from the MainPage.css file. it only specifies color so add any required tailwind code
    <div className="flex graphite-bg h-fit justify-center items-start">
      <div className="card">
        <Header />

        <OurClient />

        <JobDescription />

        <RequirementsAndResponsibilities />

        <ThankYou />
        {settings.confidential ? (
          <NdaSection documentHash={getFromData("documentHash", data)} />
        ) : (
          <HowToProceed
            documentHash={getFromData("documentHash", data)}
            accepted={getFromData("Accepted", data)}
          />
        )}
      </div>
    </div>
  );
}

export default MainPage;
