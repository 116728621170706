async function expireBriefDoc(documentHash, feedback = "THIS IS A TEST") {
      try {
      const formData = new FormData();
      formData.append('feedback', feedback);
      formData.append('action', 'expire');

     await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
        method: 'POST',
        body: formData,
      });

      alert("Feedback saved. Document expired.");

    } catch (error) {
      console.error("Error expiring document:", error);
      alert("There was an error expiring the document.");
    }
    
  }

  export default expireBriefDoc;