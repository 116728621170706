import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyB-pT5ARKr_Xv9ts_O8Ol31U05JeEiiVWw",
    authDomain: "keysearch-cloud.firebaseapp.com",
    projectId: "keysearch-cloud",
    storageBucket: "keysearch-cloud.appspot.com",
    messagingSenderId: "251841439275",
    appId: "1:251841439275:web:cbe73d99ee67d0aa112c6e"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
