import { useContext, useRef, useState } from "react";
import { DataContext } from "../context/DataContext";
import getFromData from "../helperFunctions/getFromData";
import expireBriefDoc from "../networking/expireBriefDoc";
import sendSignature from "../networking/sendSignature";
import { SettingsContext } from "../context/SettingsContext";
import NdaDialog from "../components/ndaDialog";

function NdaSection(props) {
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);
  const popup = useRef();
  const textField = useRef();
  const ndaDialog = useRef();
  const [signatureError, setSignatureError] = useState("");
  const [notInterestedClickedOnce, setNotInterestedClickedOnce] =
    useState(false);
  const [expiredConfirmed, setExpiredConfirmed] = useState(false);

  function showPopup() {
    popup.current.showModal();
  }

  function showNdaDialog() {
    ndaDialog.current.showModal();
  }

  function refreshWholePage() {
    window.location.reload();
  }

  const candidateName = getFromData("CandidateName", data);

  async function handleSubmit() {
    if (settings.preview) {
      return;
    }

    if (
      textField.current.value === "" ||
      textField.current.value === " " ||
      textField.current.value === undefined
    ) {
      setSignatureError("Please type your name to sign the NDA.");
    } else if (textField.current.value !== candidateName) {
      setSignatureError("Please type your name exactly as it appears above.");
    } else {
      setSignatureError("");

      await sendSignature(props.documentHash);
      showPopup();
      // implement logic later
    }
  }

  async function handleNotInterested() {
    if (settings.preview) {
      return;
    }

    if (notInterestedClickedOnce) {
      await expireBriefDoc(props.documentHash);
      setExpiredConfirmed(true);
    } else {
      setNotInterestedClickedOnce(true);
    }
  }

  if (expiredConfirmed) {
    return (
      <section className="flex flex-col p-8 md:p-20 bg-gray-100">
        <h2 className="font-bold text-center text-gray-800 text-lg">
          Thank you for your response!
        </h2>
        <p className="py-8 text-center">
          We hope to stay in touch for future opportunities.
        </p>
      </section>
    );
  }

  return (
    <>
      <section className="flex flex-col p-8 md:p-20 bg-gray-100">
        <h2 className="font-bold text-center text-gray-800 text-lg">
          If you are interested, sign the NDA to learn details
        </h2>
        <p className="py-8">
          This Non-Disclosure Agreement (NDA), effective from{" "}
          {new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}{" "}
          between Key Search GmbH and the Recipient (
          {settings.preview
            ? "<Candidate Name>"
            : getFromData("CandidateName", data)}
          ), pertains to the confidential exchange of information related to
          recruitment and job placement. It mandates that the Recipient
          maintains the confidentiality of all disclosed business details, job
          descriptions, and recruitment-related documents, disclosing them only
          when necessary and legally permitted. The agreement is binding until
          termination, which occurs after one year, upon mutual consent, or the
          conclusion of the relevant discussions, with continued protection for
          accrued obligations.
        </p>

        <button
          className="dark-turquoise-text font-bold text-center mt-4 mb-3 hover:underline"
          onClick={showNdaDialog}
        >
          Please click here to read the full agreement.
        </button>

        <p className="text-gray-500 font-light text-center mb-4">
          Please note: It becomes legally binding as of the signature date.
        </p>

        <div className="flex flex-col md:flex-row m-3 md:m-8 ">
          <div className="p-8 shadow-lg rounded-lg flex-1 bg-white flex-col m-0 md:m-4 mb-4 md:mb-0">
            <p className="mb-5">
              If you are{" "}
              <strong className="dark-turquoise-text">interested</strong>, type
              your name exactly as it appears and press the Sign button. By
              signing you attest that you have read the full agreement.
            </p>

            <p className="text-gray-500 mb-1">
              Date: {new Date().toLocaleDateString()}
            </p>

            <p className="text-gray-500 mb-4">
              {getFromData("CandidateName", data)}
            </p>

            <input
              ref={textField}
              type="text"
              style={{ fontFamily: "Courgette", fontSize: "1.5rem" }}
              className="border-2 border-gray-300 focus:border-teal-500 p-4 rounded-lg mb-4 w-full focus:outline-none"
              placeholder="Type your name here to sign"
            />
            {signatureError && (
              <p className="text-red-500 mb-4">{signatureError}</p>
            )}

            <button
              className="rounded-lg dark-turquoise-bg w-full text-white p-8 shadow-lg hover:scale-101"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
          <div className="p-8 shadow-lg rounded-lg flex-1 m-0 md:m-4 bg-white flex flex-col">
            <p className="mb-5">
              Click the button below if you are <strong>not interested</strong>{" "}
              in this opportunity. This document will expire shortly afterwards.
            </p>
            <button
              className="rounded-lg bg-gray-400 text-white p-8 shadow-lg hover:scale-101"
              onClick={handleNotInterested}
            >
              {notInterestedClickedOnce ? (
                <strong>Are you sure?</strong>
              ) : (
                "I'm not Interested"
              )}
            </button>
          </div>
        </div>
      </section>

      {!settings.preview && (
        <dialog
          ref={popup}
          className="p-10 shadow-lg rounded-2xl"
        >
          <h1 className="font-bold text-center pb-4">
            Thank you for signing the NDA
          </h1>
          <p>
            You can now access the full briefing document which contains the
            details about the client.
          </p>
          <button
            onClick={() => {
              refreshWholePage();
            }}
            className="mt-4 p-6 text-white font-bold rounded-lg dark-turquoise-bg"
          >
            Refresh page
          </button>
        </dialog>
      )}

      <NdaDialog ref={ndaDialog} />
    </>
  );
}

export default NdaSection;
