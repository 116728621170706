import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { DataContext } from "./context/DataContext";
import MainPage from "./pages/MainPage";
import LoginPage from "./pages/LoginPage"; // Placeholder component for login
import Spinner from "./components/Spinner";
import DocumentNotFound from "./components/DocumentNotFound";
import sampleData from "./sampleData";
import { useAuth } from "./context/AuthContext";
import TopBar from "./components/topBar";
import { SettingsContext } from "./context/SettingsContext";

function App() {
  const [data, setData] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [preview, setPreview] = useState(false);
  const { currentUser, loading} = useAuth(); // Using a custom hook to check Firebase auth state

  let { param } = useParams();
  const pathSegments = window.location.pathname.split('/');

  const useSampleData = false;
  const useHardcodedHash = false;

  useEffect(() => {
    const fetchData = async () => {
      if (useSampleData) {
        setData(sampleData);
        setDataLoading(false);
        return;
      }

      try {
        let urlParam = useHardcodedHash ? "2d0e1f5117b04d43" : param;
        let apiUrl = `https://api.keysearch.com/briefing/${urlParam}`;
        let previewUrl = `https://api.keysearch.com/briefing/${urlParam}?isPreview=true`;
        
        if (pathSegments.includes('preview')) {
          apiUrl = previewUrl;
          setPreview(true);
        } else if (pathSegments.includes('edit')) {
          apiUrl = previewUrl;
          setPreview(true);
          setEditable(true);
        }

        const response = await fetch(apiUrl);
        const jsonData = await response.json();

        if (jsonData.result.result === 'notFound') {
          setData({ error: "notFound" });
        } else if (jsonData.result.Expired === true) {
          setData({ error: "expired" });
        } else {
          jsonData.result.documentHash = urlParam;
          console.log(jsonData);
          setData(jsonData);
        }
      } catch (error) {
        console.error("Fetching data failed", error);
        setData({ error: "fetchFailed" });
      } finally {
        setDataLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, useSampleData, useHardcodedHash]);

  if (dataLoading) {
    return <Spinner />;
  }

  if (data.error) {
    const message = data.error === "notFound" ? "We can't find the document you're looking for." : 
                    data.error === "expired" ? "This document has expired." : 
                    "Error fetching data.";
    return <DocumentNotFound message={message} />;
  }

  return (
    <SettingsContext.Provider value={{
      confidential: data?.result?.confVersion || false,
      editable: editable,
      preview: preview,
    }}>
    <DataContext.Provider value={data.result}>
      
          <div className="flex flex-col">
            {currentUser && <TopBar />}
          {editable && !currentUser ? <LoginPage /> : <MainPage />}
          </div>
    </DataContext.Provider>
    </SettingsContext.Provider>
  );
}

export default App;
