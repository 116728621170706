import { react } from "react";
import { useAuth } from "../context/AuthContext";
import { FaSignOutAlt, FaShare } from "react-icons/fa";



function TopBar() {
  const { currentUser, logout } = useAuth();

  return (
    <div className="h-10 w-full flex bg-gray-900 text-white items-center px-6">
        { currentUser.photoURL && <img src={currentUser.photoURL} alt="profile" width={20} height={20} referrerPolicy="no-referrer" className="rounded-full mr-2" /> }
      You are logged in as {currentUser.displayName}

  

    <button className="ml-auto flex items-center bg-gray-700 px-2 py-0.5 rounded-md text-white hover:bg-gray-500 transition-all duration-200 ease-in-out">
        <FaShare />
        &nbsp;&nbsp;Get Preview Link
        </button>
   


      <button
        className="flex items-center ml-4 bg-gray-700 px-2 py-0.5 rounded-md text-white hover:bg-gray-500 transition-all duration-200 ease-in-out"
        onClick={() => {
          logout();
        }}
      >

        <FaSignOutAlt />
        &nbsp;&nbsp;Log Out

      </button>



    </div>
  );
}

export default TopBar;
