import React, { useContext } from "react";
import { FaUserTie, FaChevronRight } from "react-icons/fa"; // For Seniority
import { MdWorkOutline } from "react-icons/md"; // For Function
import { IoLocationOutline } from "react-icons/io5"; // For Location
import { AiOutlineLock } from "react-icons/ai"; // For Confidentiality
import { BiNetworkChart } from "react-icons/bi"; // For Reports Into
import { BsFillPersonLinesFill } from "react-icons/bs";
import EditableTextWithLinks from "../components/EditableTextWithLinks";
import { SettingsContext } from "../context/SettingsContext";
import { DataContext } from "../context/DataContext";
import getFromData from "../helperFunctions/getFromData";
import ExpandablePanel from "../components/ExpandablePanel";
import UploadableImage from "../components/UploadableImage";
import EditablePinkButton from "../components/EditablePinkButton";

function JobDescription(props) {
  const settings = useContext(SettingsContext);
  const data = useContext(DataContext);

  function makeIconData() {
    return [
      { icon: <FaUserTie />, title: "Seniority", value: "Seniority" },
      { icon: <MdWorkOutline />, title: "Function", value: "Function" },
      {
        icon: <IoLocationOutline />,
        title: "Location",
        value: "Location",
        data,
      },
      {
        icon: <AiOutlineLock />,
        title: "Job Confidentiality",
        value: "Confidentiality",
      },
      {
        icon: <BiNetworkChart />,
        title: "Reports into",
        value: "ReportsInto",
      },
      {
        icon: <BsFillPersonLinesFill />,
        title: "Job Title",
        value: "OpportunityTitle",
      },
    ];
  }

  function makeTextFieldData() {
    return [
      {
        title: "Business Model and Growth Ambition",
        dataKey: "BusinessModelAndGrowthAmbitions",
      },
      { title: "Scope of Responsibility", dataKey: "ScopeOfResponsibility" },
      { title: "Key Stakeholders", dataKey: "KeyStakeholders" },
      { title: "Challenges", dataKey: "Challenges" },
    ];
  }

  return (
    <section className="light-turquoise-bg flex w-full pt-5 flex-col">
      <div className="flex relative md:p-20 p-8 text-white flex-col md:flex-row">
        <div className="flex flex-col">
          <p className="min-w-32 mb-2">
            <span className="font-bold">
              {settings.confidential
                ? "The client"
                : getFromData("ClientName", data)}
            </span>{" "}
            wants you as their
          </p>
          <p className="font-extrabold text-xl">
            {getFromData("OpportunityTitle", data)}
          </p>
        </div>

        <EditableTextWithLinks
          enableEditing={settings.editable}
          dataKey="Teaser"
          overrideKey={"Teaser"}
          className="md:px-8 w-full mt-4 md:mt-0"
          text={props.teaser}
        />

        <div className="rightBracket hidden md:flex" />
      </div>

      {(settings.editable || (!settings.confidential && getFromData("ClientPresentationLink", data) && getFromData("ClientPresentationLink", data).includes("http"))) && (
        <EditablePinkButton
          text="Client Presentation"
          dataKey="ClientPresentationLink"
          className="mb-10"
        />
      )}

      <div id="white-params-section" className="bg-white p-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {makeIconData().map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center p-4 shadow-lg rounded-lg hover:scale-101"
            >
              <div className="text-3xl dark-turquoise-text">{item.icon}</div>
              <p className="mt-2 font-semibold">{item.title}</p>
            
              <p> {    <EditableTextWithLinks
            enableEditing={settings.editable}
            dataKey={item.value}
          /> } </p>
            </div>
          ))}
        </div>
      </div>

      {!settings.confidential && (
        <div className="lightgrey-bg pb-20">
          <h2 className="header-2-black pt-16 pb-10">THE VISION</h2>
          <EditableTextWithLinks
            enableEditing={settings.editable}
            className="px-10 lg:px-40"
            dataKey="GoalsAndVision"
          />
        </div>
      )}

      {!settings.confidential &&
        (settings.editable ? (
          UploadableImage({
            dataKey: "Image3Url",
            className: "object-cover",
            style: { aspectRatio: "16 / 3.5" },
          })
        ) : (
          <img
            className="object-cover"
            src={
              getFromData("Image3Url", data) !== "-"
                ? getFromData("Image3Url", data)
                : "https://via.placeholder.com/400x200"
            }
            alt="Media"
            style={{ aspectRatio: "16 / 3.5" }}
          />
        ))}
      {!settings.confidential && (
        <div className="dark-turquoise-bg p-2 md:p-20">
          {makeTextFieldData().map((item, index) => (
            <div
              className="flex flex-col md:flex-row mt-6 lg:mt-0 text-center md:text-left"
              key={`jobBox-${index}`}
            >
              <p
                className="text-white font-bold min-w-20 md:min-w-40 pb-6 md:pb-0 text-lg leading-0.5 md:max-w-10"
                style={{ lineHeight: "1.4rem" }}
              >
                {item.title}
              </p>
              <div className=" light-turquoise-text ml-3 mr-3 hidden md:flex mt-1">
                <FaChevronRight />
              </div>

              <ExpandablePanel title={item.title} className="mb-8 w-full">
                {" "}
                <EditableTextWithLinks
                  enableEditing={settings.editable}
                  dataKey={item.dataKey}
                  className="mx-8 text-justify"
                />
              </ExpandablePanel>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default JobDescription;
