import React from "react";
import RocketIcon from "../assets/rocket.png";
import BuildingIcon from "../assets/building.png";
import SettingsIcon from "../assets/settings.png";
import FundingIcon from "../assets/funding.png";
import MarketIcon from "../assets/market.png";
import { DataContext } from "../context/DataContext";
import { useContext, useRef, useState, useEffect } from "react";
import getFromData from "../helperFunctions/getFromData";
import { SettingsContext } from "../context/SettingsContext";
import { FaChevronDown } from "react-icons/fa";
import sendUpdate from "../networking/sendUpdate";
import EditableTextWithLinks from "../components/EditableTextWithLinks";
import EditablePinkButton from "../components/EditablePinkButton";
import UploadableImage from "../components/UploadableImage";
import SegmentedControl from "../components/segmentedControl";
import { useAuth } from "../context/AuthContext";

function OurClient(props) {
  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);
  const videoUrlFieldRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(getFromData("VideoUrl", data));
  const [selectedVideoOption, setSelectedVideoOption] = useState(false); 


  const { currentUser, loading } = useAuth();

  async function onPhotoVideoSelectionChange(option) {
    setSelectedVideoOption(option);
    const token = await currentUser.getIdToken();
    sendUpdate(data.documentHash, "ShowVideo", option, token);
  }

  async function handleAddVideoButtonClick() {
    console.log("Add Video button clicked");
    setVideoUrl(videoUrlFieldRef.current.value);
    const token = await currentUser.getIdToken();
    sendUpdate(data.documentHash, "VideoUrl", videoUrlFieldRef.current.value, token);

    console.log(videoUrl);
  }

  function makeIconData() {
    return [
      {
        icon: RocketIcon,
        text: "Year Founded",
        value: "ClientYearFounded",
      },
      {
        icon: BuildingIcon,
        text: "Vertical",
        value: "ClientVertical",
      },
      {
        icon: SettingsIcon,
        text: "Category",
        value: "ClientCategory",
      },
      {
        icon: FundingIcon,
        text: "Funding",
        value: "ClientFunding",
      },
      {
        icon: MarketIcon,
        text: "Model",
        value: "ClientSalesModel",
      },
    ];
  }

  useEffect(() => {
    if (getFromData("ShowVideo", data) === true || getFromData("ShowVideo",data) === "true") {
      setSelectedVideoOption(true);
    }
  }, []);

  return (
    <section className="navy-bg flex w-full pt-5 flex-col">
      <div className="flex flex-col p-20">
        <h2 className="header-2-left">OUR CLIENT</h2>
        <p className="text-white text-3xl font-extrabold mb-5">
          {!settings.confidential
            ? getFromData("ClientName", data)
            : "A Top Industry Player"}
        </p>
        
        

        {EditableTextWithLinks({
                  className: "text-white",
                  enableEditing: settings.editable,
                  dataKey: "ClientDescription",
                })}

        

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 justify-center mt-24 mx-12 md:mx-16 lg:mx-16">
          {makeIconData().map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center space-y-4 h-full"
            >
              <img
                src={item.icon}
                alt="icon"
                className="h-12 w-12 object-contain" // Specify a fixed size for consistency
              />
              <div className="text-center">
                {" "}
                {/* Ensure text alignment */}
                <p className="text-white text-xs">{item.text}</p>
                {/*    <p className="text-white font-bold text-sm">{getFromData(item.value, data)}</p> */}
                {EditableTextWithLinks({
                  className: "text-white font-bold text-sm",
                  enableEditing: settings.editable,
                  dataKey: item.value,
                })}
              </div>
            </div>
          ))}
        </div>
        {!settings.confidential && (
          <EditablePinkButton
            text="Visit their website"
            dataKey="ClientWebsite"
            className="mt-20"
          />
        )}
      </div>
      {!settings.confidential && (
        <div className="flex">
          <div id="mediarow" className="flex w-full flex-col md:flex-row bg-red-300">
            <div className="flex-1 w-hal relative" style={{ aspectRatio: "16 / 9"}}>
            {selectedVideoOption ? (
              <div className="flex flex-col relative">
                {settings.editable && (
                  <div className="flex absolute w-full">
                    <input
                      defaultValue={videoUrl}
                      type="text"
                      ref={videoUrlFieldRef}
                      placeholder="Add a YouTube/Vimeo URL"
                      className="w-3/4 h-12 bg-white rounded-l-lg p-4"
                    />
                    <button
                      onClick={handleAddVideoButtonClick}
                      className="w-1/4 h-12 bg-pink-500 rounded-r-lg flex text-white items-center justify-center"
                    >
                      <FaChevronDown /> &nbsp;Add Video
                    </button>
                  </div>
                )}

                <iframe

                  title="YouTube Video"
                  src={extractVideoID(videoUrl)}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "16 / 9",
                    
                  }}
                ></iframe>
              </div>
            ) : (
              <UploadableImage
                dataKey="Image1Url"
                style={{
                  width: "100%",
                  aspectRatio: "16 / 9",
                }}
              />
            )}
            
            { /* Add a segmented control here to choose either the image or the video */ }
            { settings.editable &&
            <SegmentedControl className="absolute bottom-4 left-4" initialOption={selectedVideoOption} onSelectionChange={ onPhotoVideoSelectionChange } ></SegmentedControl>
}
            </div>
            <div className="flex-1 bg-white items-center justify-items-center flex" style={{
              aspectRatio: "16 / 9",
            }}>
             {getFromData("ClientId", data) !== undefined ? (
                <img
                  src={`https://keysearch.com/int/orgs/${getFromData(
                    "ClientId",
                    data
                  )}.png`}
                  alt="Secondary Media"
                  className="w-3/4 h-full object-contain px-16 bg-white py-10 md:py-0 mx-auto"
                />
              ) : (
                <div className="bg-red-400 flex "></div>
              )} 
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

function extractVideoID(url) {
  if (!url) return "";

  let id = "";
  let embedUrl = "";

  if (url.includes("youtu.be")) {
    id = url.split("youtu.be/")[1];
    embedUrl = `https://www.youtube.com/embed/${id}`;
  } else if (url.includes("youtube.com")) {
    const urlParams = new URLSearchParams(new URL(url).search);
    id = urlParams.get("v");
    embedUrl = `https://www.youtube.com/embed/${id}`;
  } else if (url.includes("vimeo.com")) {
    id = url.split("vimeo.com/")[1];
    embedUrl = `https://player.vimeo.com/video/${id}`;
  }

  return embedUrl;
}


// Use this function in the iframe src attribute

export default OurClient;
