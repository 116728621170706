import {react} from 'react';
import Robot from '../components/Robot.js';
import Typist from 'react-typist-component';

function DocumentNotFound(props) {
    return (
        <div className='h-screen w-full flex items-center justify-center flex-col'>
           <div className='w-28 h-28'><Robot />
           
       
           </div>
           <Typist count={20} typingDelay={40}>
           <p className='text-lg font-semibold pt-4'>{props.message}</p>

           </Typist>

          <p className='pt-4'> Visit <a className='dark-turquoise-text' href="https://keysearch.com" >keysearch.com</a> for information about us!</p>
        </div>
    );
}

export default DocumentNotFound;