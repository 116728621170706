async function sendSignature(documentHash, feedback = "THIS IS A TEST") {
    try {
    const formData = new FormData();
    formData.append('hash', documentHash);
    formData.append('action', 'sign');
    formData.append('feedback', feedback);

   await fetch(`https://api.keysearch.com/briefing/${documentHash}`, {
      method: 'POST',
      body: formData,
    });

    alert("Done!");

  } catch (error) {
    console.error("Error signing", error);
    alert("There was an error signing the document.");
  }
  
}

export default sendSignature;