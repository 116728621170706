import React, { useContext, useRef } from "react";
import ArrowPicture from "../assets/arrow.png";
import WebsitePicture from "../assets/small_website.png";
import LinkedinPicture from "../assets/small_linkedin.png";
import getFromData from "../helperFunctions/getFromData";
import { DataContext } from "../context/DataContext";
import { SettingsContext } from "../context/SettingsContext";
import UploadableImage from "../components/UploadableImage";

function ThankYou(props) {

  const data = useContext(DataContext);
  const settings = useContext(SettingsContext);


  return (
 
    <section className="lightgrey-bg flex w-full flex-col text-sm">
      {
        settings.confidential ? <div className="m-8"/> :
       (
        settings.editable ? 
       UploadableImage({dataKey:"Image2Url", className:"object-cover", style:{ aspectRatio: "16 / 3.5" }}) :
        <img
        className="object-cover"
        src={getFromData("Image2Url", data)}
        alt="Media"
        style={{ aspectRatio: "16 / 3.5" }}
      />) }

      <div className="light-turquoise-bg p-8 md:p-16 flex flex-col md:flex-row ml-0 md:ml-32 mt-0 md:-mt-8 relative">
        <div style={{ color: "white", flex: 1.5, marginLeft: "10px" }}>
          <p className="font-bold text-white text-xl">Thank you,</p>
          <p className="font-bold dark-turquoise-text text-xl mb-5 md:mb-2">
          { settings.preview ? "Candidate Name" : getFromData("CandidateName", data)}
          </p>
          <p>
            I think your experience at {getFromData("CandidateCurrentCompany", data)} would be
            a great match!
            <br />
            Looking forward to continuing in the process,
          </p>

          <div className="flex items-center gap-2 mt-6">
            <div className="md:hidden flex-col items-center justify-center flex">
              <img
                className="rounded-full"
                src={getFromData(settings.preview ? "OwnerPhoto" : "ConsultantPhoto", data)}
                width={40}
                height={40}
                alt="Consultant"
                style={{
                  boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.5)",
                }}
              />
            </div>
            <div className="flex flex-col justify-center">
              {" "}
              {/* Adjusted for vertical centering */}
              <p className="font-semibold">{getFromData(settings.preview ? "OwnerName" : "ConsultantName", data)}</p>
              <p>{"Partner"} at Key Search</p>
            </div>
          </div>

          <img
            className="mt-3"
            src={getFromData(settings.preview ? "OwnerSignature":"ConsultantSignature", data)}
            alt="signature"
            width={140}
            style={{ filter: "brightness(0) invert(1)" }}
          />
        </div>

        <div className="mt-auto mb-auto pt-10 md:pt-20">
          <img
            src={ArrowPicture}
            alt="white arrow"
            className="opacity-50 w-20 hidden md:flex"
          />
        </div>

      { !settings.confidential && <div className="flex flex-1 my-auto flex-col md:flex-row px-8 md:px-0 md:ml-4">
          <a href={getFromData("ClientWebsite", data)} target="_blank" rel="noopener noreferrer" className="bg-white w-full flex items-center justify-center py-6 aspect-square rotate-1 shadow-lg rounded-sm hover:rotate-0">
    
            <img src={`https://keysearch.com/int/orgs/${getFromData("ClientId", data)}.png`} alt="logo" width={140} />
         
          </a>
        </div> }

        <div className="hidden md:absolute left-0 md:-left-16 bottom-0 md:flex flex-col items-start">
          <a href={getFromData(settings.preview ? "OwnerWebsiteLink" : "ConsultantWebsiteLink", data)} target="_blank" rel="noopener noreferrer">
          <img
            className="rounded-full hover:scale-105"
            src={getFromData(settings.preview ? "OwnerPhoto" :"ConsultantPhoto", data)}
            width={120}
            alt="Consultant"
            style={{
              boxShadow: "3px 3px 7px 0px rgba(0,0,0,0.75)",
            }}
          />
</a>
          <button
            className="mt-2 ml-4 hover:scale-105"
            onClick={() => window.open(getFromData(settings.preview ? "OwnerWebsiteLink" : "ConsultantProfileLink", data), "_blank")}
          >
            <img src={LinkedinPicture} alt="Our Website" width={24} />
          </button>

          <button
            className="mt-2 ml-4 hover:scale-105"
            onClick={() => window.open(getFromData(settings.preview ? "OwnerWebsiteLink" : "ConsultantWebsiteLink", data), "_blank")}
          >
            <img src={WebsitePicture} alt="Our Website" width={24} />
          </button>
        </div>
      </div>
    </section>


  );


}

export default ThankYou;
