import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const ExpandablePanel = ({ title, children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const togglePanel = () => {
    setContentHeight(isOpen ? 0 : contentRef.current.scrollHeight);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) setContentHeight(contentRef.current.scrollHeight);
  }, [isOpen]);

  return (
    <div className={className}>
      <div className="mx-auto bg-white rounded-lg shadow-lg">
        <div
          className="p-4 flex justify-between items-center cursor-pointer"
          onClick={togglePanel}
        >
          <h2 className="text-lg text-gray-300">{!isOpen ? "Click to read" : "Click to close"}</h2>
          <FaChevronDown
            className={`h-5 w-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          />
        </div>
        <div
          className={`overflow-hidden transition-max-height duration-300 ease-in-out`}
          style={{ maxHeight: isOpen ? `${contentHeight}px` : '0px' }}
        >  
          <div ref={contentRef} className="p-4 -mt-4 mb-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandablePanel;
