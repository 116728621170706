import React, { useState, useEffect } from 'react';
import './segmentedControl.css';

function SegmentedControl({ onSelectionChange, className, initialOption }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const switchSegment = (index) => {
    setActiveIndex(index);
    onSelectionChange(index === 0 ? true : false); // Pass the selected option to the parent
  };


  useEffect(() => {
    setActiveIndex(initialOption ? 0 : 1);
  }, [initialOption]);
  



  return (
    <div className={`segmented-control ${className}`}>
      <button
        className={activeIndex === 0 ? 'active' : ''}
        onClick={() => switchSegment(0)}
      >
        Video
      </button>
      <button
        className={activeIndex === 1 ? 'active' : ''}
        onClick={() => switchSegment(1)}
      >
        Photo
      </button>
    </div>
  );
}

export default SegmentedControl;
