import react, {forwardRef} from 'react';

const NdaDialog = forwardRef((props, ref) => {

  return <dialog
        ref={ref}
        className="p-10 shadow-lg rounded-2xl"
      >
         <div class="max-w-4xl mx-auto p-6">
        <h1 class="text-xl font-bold mb-4">Non Disclosure Agreement</h1>
        <p class="mb-4">This is an Agreement, effective as of  {new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}, between Key Search GmbH ("Key Search") and the recipient of this document ("Recipient"). It is recognized that the exchange of information may be necessary or desirable in the pursuit of mutually beneficial business activities associated with the recruiting and job placement of one or more clients. It is understood and agreed that the discloser of confidential information will provide information that must be kept confidential. To ensure the protection of such information and preserve any necessary confidentiality, it is agreed that:</p>
        
        <p class="font-semibold mb-2">The Confidential Information to be disclosed can be described as and includes:</p>
        <ul class="list-disc list-inside mb-4">
            <li>Any and all business information related to the recruitment and job placement.</li>
            <li>Any and all job descriptions related to a job opportunity.</li>
            <li>Any documents, agreements, resumes, CVs, etc., associated with the recruitment.</li>
            <li>Any and all pertinent materials, information, or trade secrets.</li>
            <li>These items include any past, current, or future confidential information at the time of this disclosure.</li>
            <li>The existence of the job position itself.</li>
            <li>The identity of the company seeking to hire for this job position.</li>
        </ul>

        <p class="font-semibold mb-2">The Recipient shall limit disclosure of Confidential Information to individuals who have a need to know. The Recipient will not disclose the confidential information obtained from Key Search unless required by law. This Agreement imposes no obligation upon the Recipient concerning any Confidential Information that:</p>
        <ol class="list-decimal list-inside mb-4">
            <li>was in the Recipient's possession before the receipt of it from the Discloser;</li>
            <li>is or becomes public knowledge through no fault of the Recipient;</li>
            <li>is rightfully received by the Recipient from a third party without a duty of confidentiality;</li>
            <li>is independently developed by the Recipient.</li>
        </ol>

        <p class="mb-4">This Agreement represents the entire agreement between Key Search and the Recipient concerning the disclosure of Confidential Information. Any addition or modification to this Agreement must be made in writing and signed by the Recipient. If any provisions of this Agreement are found to be unenforceable, the remainder shall be enforced as fully as possible.</p>

        <p class="mb-4">Breach of this Agreement by the Recipient may be enforced through legal measures as deemed appropriate by Key Search. Subject to Key Search’s defeat in a court proceeding, any and all legal fees and costs associated with legal actions will be paid for by the Recipient.</p>

        <p class="mb-4">The agreement shall terminate upon the earlier of (i) mutual written agreement of the parties, (ii) termination of discussions between the parties regarding the Confidential Information, or (iii) 1 year from the date of execution of this agreement. Upon termination, all obligations of confidentiality and non-use under this agreement shall cease, except that any obligations or liabilities that have accrued prior to the termination date shall survive such termination.</p>

        <p class="mb-4">No variation of this NDA is valid or shall be binding unless confirmed in writing by Key Search and shall be governed by and construed in accordance with the laws of Switzerland. All disputes arising out of or in connection with this Agreement that the parties are unable to settle amicably shall be subject to the exclusive jurisdiction of the Commercial Court of Zurich.</p>
    </div>
        <button
        className="dark-turquoise-bg text-white p-4 rounded-lg mt-4 mx-auto flex"
        onClick={() => ref.current.close()}>Close</button>
      </dialog>
}
);

export default NdaDialog;