import React from "react";

const addLinksToText = (inputText) => {

  if (!inputText) {
    return '-';
  }


    if (typeof inputText !== "string") {
      return inputText;
    }

    const urlRegex =
      /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return inputText.split("\n").map((line, index) => {
      const parts = line.split(urlRegex);
      return (
        <React.Fragment key={index}>
          {parts.map((part, partIndex) => {
            if (part.match(urlRegex)) {
              return (
                <a
                  key={partIndex}
                  href={part}
                  style={{ color: "#00a4ad" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part}
                </a>
              );
            }
            return part;
          })}
          {index < inputText.split("\n").length - 1 ? <br /> : ""}
        </React.Fragment>
      );
    });
  };

  export default addLinksToText;