import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App'; // Assume this is your App component

const RouterSetup = () => (
  <Router>
    <Routes>
      <Route path="/:param" element={<App/>} />
      <Route path="/" element={<App/>} />
      <Route path="/edit/:param" element={<App/>} />
      <Route path="/preview/:param" element={<App/>} />
    </Routes>
  </Router>
);

export default RouterSetup;
