import React, { useContext, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import debounce from "../helperFunctions/debounce";
import addLinksToText from "../helperFunctions/addLinks";
import { DataContext } from "../context/DataContext";
import { data } from "autoprefixer";
import getFromData from "../helperFunctions/getFromData";
import sendUpdate from "../networking/sendUpdate";

// Input should be formatted like this:
// {jsonKey: xx, value: yy}

const EditableTextWithLinks = ({
  className,
  enableEditing = false,
  dataKey,
}) => {
  data = useContext(DataContext);
  const { currentUser, loading } = useAuth();

  const sendUpdateToServer = useCallback( 
   async (e) => {
      if (currentUser && !loading) {
        const value = e.target.innerText;
        console.log("Saving", dataKey, value);
        const token = await currentUser.getIdToken();
        sendUpdate(data.documentHash, dataKey, value, token).then((res) => {
          console.log(res);
        });
      }
    },
    [currentUser, loading, dataKey]
  );
  
  const debouncedOnInput = useCallback(
    debounce((e) => {
      console.log("Debounced input");
      if (sendUpdateToServer) sendUpdateToServer(e);
    }, 1000),
    [sendUpdateToServer]
  );

  const editableProps = enableEditing
    ? {
        contentEditable: true,
        suppressContentEditableWarning: true,
        onInput: (e) => {
          console.log("starting debounce");
          debouncedOnInput(e);
        }
      }
    : {};

    {/*
  if (getFromData(dataKey, data) === "-") {
    return <p>-</p>;
  } */ }



  return (
    <p className={className} {...editableProps}>
  

      {addLinksToText(getFromData(dataKey, data) || '-')}
    </p>
  );
};

export default EditableTextWithLinks;
